import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import AdsBanner from './AdsBanner'
import { useFooterBitlys } from './hooks'

const Footer = () => {
  const data = useStaticQuery(graphql`
    query socialsQuery {
      site {
        siteMetadata {
          social {
            image
            twitter
            facebook
            instagram
            youtube
          }
        }
      }
    }
  `)
  const {
    site: {
      siteMetadata: { social },
    },
  } = data

  const [bitlys] = useFooterBitlys(social)

  return (
    <>
      {data && social && (
        <footer id="footer">
          <AdsBanner
            className="adsbygoogle"
            style={{ display: 'block' }}
            slot="XXX"
            format="auto"
            responsive="true"
          />
          <div className="inner">
            <ul className="icons">
              <li>
                <a
                  href={`${bitlys?.twitterBitly}`}
                  className="icon alt fa-twitter"
                >
                  <span className="label">Twitter</span>
                </a>
              </li>
              <li>
                <a
                  href={`${bitlys?.facebookBitly}`}
                  className="icon alt fa-facebook"
                >
                  <span className="label">Facebook</span>
                </a>
              </li>
              <li>
                <a
                  href={`${bitlys?.instagramBitly}`}
                  className="icon alt fa-instagram"
                >
                  <span className="label">Instagram</span>
                </a>
              </li>
              <li>
                <a
                  href={`${bitlys?.youtubeBitly}`}
                  className="icon alt fa-youtube"
                >
                  <span className="label">Youtube</span>
                </a>
              </li>
            </ul>
            <ul className="copyright">
              <li>&copy; {new Date().getFullYear()} bloomB, The Bloom Bunch</li>
            </ul>
          </div>
        </footer>
      )}
    </>
  )
}

export default Footer
