import React, { useEffect, useState } from 'react'
import '../assets/scss/main.scss'
import Header from './Header'
import Menu from './Menu'
// import Contact from './Contact'
import Footer from './Footer'

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const [state, setState] = useState({
    isMenuVisible: false,
    loading: 'is-loading',
  })

  let timeoutId: NodeJS.Timeout

  useEffect(() => {
    timeoutId = setTimeout(() => {
      setState({ ...state, loading: '' })
    }, 100)

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [])

  const handleToggleMenu = () => {
    setState({ ...state, isMenuVisible: !state.isMenuVisible })
  }

  return (
    <div
      className={`body ${state.loading} ${
        state.isMenuVisible ? 'is-menu-visible' : ''
      }`}
    >
      <div id="wrapper">
        <Header onToggleMenu={handleToggleMenu} />
        {children}
        {/* <Contact /> */}
        <Footer />
      </div>
      <Menu onToggleMenu={handleToggleMenu} />
    </div>
  )
}

export default Layout
