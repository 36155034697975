import React from 'react'
import { Link } from 'gatsby'
import { AnchorClick } from '../utils/types'
import logo from '../assets/images/bloomB-branding-logo-v2.png'
import { useLoggedInUser } from './hooks'

const Header = (props: { onToggleMenu: AnchorClick }) => {
  const [loggedInUsername] = useLoggedInUser()
  return (
    <header id="header" className="alt">
      <Link to="/" className="logo" style={{}}>
        <img src={logo} style={{ marginTop: '5px' }} />
        {/* <span style={{ position: 'relative', top: '-40px' }}>
        The Bloom Bunch
      </span> */}
      </Link>
      <nav>
        <a className="menu-link" onClick={props.onToggleMenu}>
          {loggedInUsername ? `${loggedInUsername}'s` : 'MENU'}
        </a>
      </nav>
    </header>
  )
}

export default Header
