import React, { useContext } from 'react'
import { Link, navigate } from 'gatsby'
import { AnchorClick } from '../utils/types'
import {
  isLoggedIn,
  logout,
  updateUserLoginLogoutActivity,
} from '../services/firebase'
import {
  GlobalStateContext,
  UserDispatchContext,
} from './context/GlobalContextProvider'
import { getUsername, handleWebHook } from '../utils'
import { useLoggedInUser } from './hooks'

const Menu = (props: { onToggleMenu: AnchorClick }) => {
  const globalStateContext = useContext<any>(GlobalStateContext)
  const dispatch = useContext<any>(UserDispatchContext)
  const [loggedInUsername] = useLoggedInUser()

  const handleLogout = () => {
    handleWebHook({
      text: `${getUsername(
        globalStateContext.accessProfile.username
      )} is logging out...`,
    })
    // updates last login date and time when you logout
    updateUserLoginLogoutActivity({
      ref: `activites/${globalStateContext.user.uid}/lastlogout`,
      data: new Date().getTime(),
    })
      .then(() => {
        logout({})
      })
      .then(() => {
        dispatch({ type: 'LOGOUT' })
        dispatch({
          type: 'MESSAGE',
          payload: {
            type: 'info',
            text: 'Successfully Signed out!',
          },
        })
      })
      .then(() => {
        navigate('/app/login')
      })
  }
  return (
    <nav id="menu">
      <div className="inner">
        <ul className="links">
          <li>
            <Link onClick={props.onToggleMenu} to="/">
              Home
            </Link>
          </li>
          <li>
            <Link onClick={props.onToggleMenu} to="/team">
              Team
            </Link>
          </li>
          <li>
            <Link onClick={props.onToggleMenu} to="/journals">
              Journals
            </Link>
          </li>
          <li>
            <Link onClick={props.onToggleMenu} to="/app/dashboard">
              Dashboard
            </Link>
          </li>
          <li>
            <Link onClick={props.onToggleMenu} to="/forum">
              Ask a Doctor Online
            </Link>
          </li>
          {/*<li>
          <Link onClick={props.onToggleMenu} to="/generic">
            Generic
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/elements">
            Elements
          </Link>
        </li> */}
        </ul>
        <ul className="actions vertical">
          {/* <li>
          <a href="/#" className="button special fit">
            Get Started
          </a>
        </li> */}
          <li>
            {!isLoggedIn() ? (
              <Link
                to="/app/login"
                className="button fit"
                onClick={props.onToggleMenu}
              >
                Log In
              </Link>
            ) : (
              <a
                className="button fit"
                onClick={() => {
                  props.onToggleMenu()
                  handleLogout()
                }}
              >
                Log out {loggedInUsername}
              </a>
            )}
          </li>
        </ul>
      </div>
      <a className="close" onClick={props.onToggleMenu}>
        Close
      </a>
    </nav>
  )
}

export default Menu
function UserStateContext<T>(UserStateContext: any) {
  throw new Error('Function not implemented.')
}
